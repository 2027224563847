import { Header, LoginForm, InputGroup, SubmitButton } from "./style";

import {
  loginAction,
  sendPasswordEmail,
  sessionCheck,
} from "../../helpers/api";
import { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { Button, Alert, Form, Modal, Input } from "antd";
// import logo from "../../assets/images/OpenCase transparent.png";
import logo from "../../assets/images/raire_color.png";

let timer = null;

const LoginPage = (props) => {
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [msg, setMsg] = useState({ msg: "", type: "" });
  const [loading, setLoading] = useState(false);
  const [passwordResetEmail, setPasswordResetEmail] = useState("");
  const [resetPassword, setResetPassword] = useState(false);
  const [sending, setSending] = useState(false);
  const [passwordMsg, setPasswordMsg] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    sessionCheck().then((res) => {
      if (res.status === 200) navigate("/search");
    });
  }, []);

  const updateMessage = (msg) => {
    clearTimeout(timer);
    setMsg(msg);
    timer = setTimeout(() => {
      setMsg({ msg: "", type: "" });
    }, 100000);
  };

  const handleSubmit = (e) => {
    e?.stopPropagation();
    e?.preventDefault();

    login();
  };

  const login = () => {
    setLoading(true);

    loginAction(name, password)
      .then((res) => {
        localStorage.setItem("token", res.data.access_token);
        localStorage.setItem("email", name);

        setLoading(false);
        navigate("/search");
      })
      .catch((e) => {
        const detail = e?.response?.data?.detail;
        const msg = Array.isArray(detail) ? detail?.[0]?.msg : detail;
        updateMessage({ msg, type: "error" });
        setLoading(false);
      });
  };

  const onClose = () => {
    setResetPassword(false);
  };

  const sendResetPasswordEmail = () => {
    setSending(true);
    sendPasswordEmail(passwordResetEmail)
      .then((res) => {
        setSending(false);
        setPasswordMsg("Email sent, check your inbox");
      })
      .catch((e) => {
        setSending(false);
        setPasswordMsg("oops!, something went wrong");
      });
  };

  return (
    <div style={{ borderTop: "1px solid transparent" }}>
      <LoginForm style={{ marginTop: props.popup ? 0 : undefined }}>
        <div className="loginContainer">
          <div className="banner">
            <img src={logo} alt="logo" />
            <Header>
              Access thousands of electronic patient records in rare diseases.
              Simple searches and intuitive visualizations help navigate research-grade real world data in no time.
              Contact us at info@rairedata.com for more information. 
              <Link to="/subscriptions"> See our plans</Link>
            </Header>
          </div>

          <Form onFinish={handleSubmit} className="loginForm">
            {msg.msg ? (
              <Alert showIcon message={msg.msg} type={msg.type} />
            ) : null}

            <InputGroup>
              <label>Username</label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </InputGroup>
            <InputGroup>
              <label>Password</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </InputGroup>
            <SubmitButton>
              <Link
                to="/signup"
                style={{
                  padding: "10px 30px",
                  boxShadow: "none",
                  fontSize: "16px",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
              >
                Sign up
              </Link>
              <Button
                htmlType="submit"
                loading={loading}
                onClick={handleSubmit}
              >
                Login
              </Button>
            </SubmitButton>
          </Form>
        </div>
        <span
          style={{
            clear: "both",
            display: "block",
            textAlign: "center",
            paddingTop: 20,
          }}
        >
          Forgot your password ?{" "}
          <Link to="#" onClick={() => setResetPassword(true)}>
            Click here to reset.
          </Link>
        </span>
      </LoginForm>

      <Modal
        visible={resetPassword}
        title="Reset password"
        onCancel={onClose}
        onOk={onClose}
        style={{ background: "#fff" }}
        footer={
          <Button
            onClick={sendResetPasswordEmail}
            type="primary"
            loading={sending}
          >
            Send email
          </Button>
        }
      >
        <div>
          {passwordMsg || null}
          <div>Please enter your email to receive a reset password link</div>
          <Input
            name="email"
            value={passwordResetEmail}
            onChange={(e) => setPasswordResetEmail(e.target.value)}
          />
        </div>
      </Modal>
    </div>
  );
};

export default LoginPage;
